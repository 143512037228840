import React from "react";
import classNames from "classnames";
import { Icon, IconProps } from "./Icon";

interface CloserIconProps extends Omit<IconProps, "icon" | "type"> {
  size?: "small" | "medium" | "large" | "extra-large";
  /** Creates a closer toggle style */
  closes?: boolean;
  /** For darker background */
  darker?: boolean;
}

export const CloserIcon = ({
  className,
  size = "medium",
  closes = false,
  darker,
  ...props
}: CloserIconProps): JSX.Element => {
  const type = size === "small" ? "DELETE_X" : "CLOSER";
  return (
    <Icon
      {...props}
      className={classNames("sb-icon-closer", `${size}`, className, {
        closes,
        darker,
      })}
      type={type}
    />
  );
};

export type DeleteXColor = "red" | "black" | "employee-only";
interface DeleteXProps extends CloserIconProps {
  color?: DeleteXColor;
  size?: "small" | "medium" | "large";
}

export const DeleteX = ({
  className,
  color = "black",
  size = "small",
  ...props
}: DeleteXProps): JSX.Element => (
  <CloserIcon
    {...props}
    className={classNames("sb-delete-x", className, color)}
    size={size}
  />
);
