import React from "react";
import { ErrorBoundary } from "./ErrorBoundary";

// TODO(stefan): Extract to a shared lib after utils refactor
const getDisplayName = (WrappedComponent) => {
  return WrappedComponent.displayName || WrappedComponent.name || "Component";
};

const errorBoundaryName = (WrappedComponent) =>
  `ErrorBoundary(${getDisplayName(WrappedComponent)})`;

/**
 * HOC that creates a component configured with an error boundary.
 * Wrap the error boundary as part of the component export.
 *
 * Example usage:
 * ```
 * export default withErrorBoundary(options)(Component)
 * ```
 * @param {JSX.Element} Component The Component inside the error boundary.
 * @param {ErrorBoundary.propTypes} options ErrorBoundary props.
 */

export const withErrorBoundary = (options) => (Component) => {
  const displayName = errorBoundaryName(Component);

  const WithErrorBoundary = (props) => {
    return (
      <ErrorBoundary displayName={displayName} {...options}>
        <Component {...props} />
      </ErrorBoundary>
    );
  };

  WithErrorBoundary.displayName = displayName;

  return WithErrorBoundary;
};
