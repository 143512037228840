import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Icon, icons } from "../Icon";

export const DefaultErrorPlaceholder = ({ className }) => {
  return (
    <div className={classNames("error-boundary-default", className)}>
      <Icon icon={icons.EXCLAIM_SOLID} className="error" />
      <span>Something went wrong.</span>
    </div>
  );
};

DefaultErrorPlaceholder.propTypes = {
  className: PropTypes.string,
};
