import React, { Component } from "react";
import PropTypes from "prop-types";
import { DefaultErrorPlaceholder } from "./DefaultErrorPlaceholder";

/**
 * Error boundaries catch JavaScript errors anywhere in their child
 * component tree, log those errors, and display a fallback UI instead of the
 * component tree that crashed. Error boundaries catch errors during rendering,
 * in lifecycle methods, and in constructors of the whole tree below them.
 *
 * Note: React rethrows all errors handled in an error boundary while in dev.
 */
export class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: undefined,
    };
  }

  static getDerivedStateFromError(error) {
    return { error };
  }

  componentDidCatch(error, errorInfo) {
    const { componentStack } = errorInfo;
    this.handleError(error, componentStack);
  }

  // eslint-disable-next-line react/destructuring-assignment
  childName = () => this.props.children?.type?.name;

  handleError = (error, componentStack) => {
    const { displayName, handleError } = this.props;
    handleError?.(error, {
      displayName: displayName ?? this.childName(),
      componentStack,
    });
  };

  render() {
    const { error } = this.state;
    const { renderError, collapseOnError, children, handleError } = this.props;

    if (!error) return children;

    if (!renderError) {
      return collapseOnError ? null : <DefaultErrorPlaceholder />;
    }

    try {
      return renderError(error);
    } catch (err) {
      handleError?.(new Error("Failed to render error."));
      return <DefaultErrorPlaceholder />;
    }
  }
}

ErrorBoundary.propTypes = {
  /** (error, meta: { displayName: string, componentStack: string }) => void */
  handleError: PropTypes.func.isRequired,
  /** (error) => React Node */
  renderError: PropTypes.func,
  /** On Error, do not show any placeholder component. */
  collapseOnError: PropTypes.bool,
  displayName: PropTypes.string,
};

ErrorBoundary.defaultProps = {
  renderError: undefined,
  collapseOnError: false,
  displayName: undefined,
};
