import React, { ReactElement, ReactNode, useState, useEffect } from "react";
import classNames from "classnames";

type SpinSize = "small" | "default";

interface SpinProps {
  className?: string;
  /** Parent container around children */
  containerClassName?: string;
  size?: SpinSize;
  delay?: number;
  children?: ReactNode;
  tip?: string;
  spinning?: boolean;
  /** Center the spinner with absolute positioning */
  centered?: boolean;
}

export const Spin = ({
  className,
  containerClassName,
  size,
  delay,
  children,
  tip,
  spinning: enabled = true,
  centered,
}: SpinProps): ReactElement | null => {
  const [isSpinning, setIsSpinning] = useState<boolean>(false);

  useEffect(() => {
    const timerId = setTimeout(() => setIsSpinning(true), delay);
    return () => clearTimeout(timerId);
  }, [delay]);

  const spinSize = {
    "sb-spin-sm": size === "small",
  };

  const spinContainerClassName = classNames(
    className,
    spinSize,
    {
      "sb-spin-show-text": !!tip,
      "sb-spin-centered": centered,
    },
    "sb-spin",
    "sb-spin-spinning"
  );

  const showSpin = enabled && isSpinning;
  const spinElement = !showSpin ? null : (
    <div className={spinContainerClassName}>
      <span
        className={classNames(
          spinSize,
          "liftoff-spinner",
          "sb-spin-dot",
          "sb-spin-dot-spin"
        )}
      />
      {tip && <div className="sb-spin-text">{tip}</div>}
    </div>
  );

  if (!children) {
    return spinElement;
  }

  return (
    <div className={classNames("sb-spin-nested-loading", containerClassName)}>
      <div>{spinElement}</div>
      <div className="sb-spin-container">{children}</div>
    </div>
  );
};

export const LoadingPageSpin = ({
  children,
  loading,
  ...rest
}: SpinProps & { loading: boolean }): JSX.Element => {
  return loading ? <Spin {...rest} /> : <>{children}</>;
};
