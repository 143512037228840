import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "@liftoff/api";
import withRetries from "shared/withRetries";
import Initializing from "shared/components/Initializing";
import { DefaultErrorBoundary } from "shared/errorBoundary";

const SignInPage = lazy(() => withRetries(() => import("./SignInPage")));
// TODO(stefan): Set these up
// import {
//   initSentry,
//   setupErrorHandler,
//   setupWhyDidYouRender,
// } from "shared/utils";

// setupErrorHandler();

// initSentry(
//   "https://47436d12c0404d74b4a3d6eb18c62c01@o429481.ingest.sentry.io/5376177"
// );

// setupWhyDidYouRender();

const render = () => {
  ReactDOM.render(
    <BrowserRouter>
      <HelmetProvider>
        <DefaultErrorBoundary>
          <Suspense fallback={<Initializing />}>
            <SignInPage />
          </Suspense>
        </DefaultErrorBoundary>
      </HelmetProvider>
    </BrowserRouter>,
    document.getElementById("root")
  );
};

render();
