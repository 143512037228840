import React from "react";
import * as Sentry from "@sentry/browser";
import {
  ErrorBoundary as SentryErrorBoundary,
  ErrorBoundaryProps,
  FallbackRender,
} from "@sentry/react";
import * as RSentry from "@sentry/react";
import {
  mergeOptions,
  configureUserScope,
  isDev,
  InitSentryOptions,
} from "./shared";

export default Sentry;
export const ReactSentry = RSentry;

/**
 * Initializes Sentry error logging and sets the scope to the current user email.
 *
 * The provided sentry config is merged with the default options.
 *
 * This uses process.env to get default configuration values.
 * NODE_ENV - development or production.
 * APP_VERSION - Version number such as ploy version or commit hash.
 * SENTRY_ENVIRONMENT - Environment name that overrides NODE_ENV.
 *
 * The default behavior can be overridden by defining `environment` or `release`.
 *
 * getUser is either a URL that will return a JSON user object or
 * a promise that will return a user object.
 * The default url for getUser is /user_info.
 * Set getUser to null to disable fetching user info.
 *
 * User object:
 * {
 *   "email": "user@liftoff.io"
 * }
 */
export async function initSentry(
  config: Sentry.BrowserOptions,
  { disabled = isDev, getUser }: InitSentryOptions = {}
): Promise<void> {
  if (disabled) return;
  Sentry.init(mergeOptions(config));
  configureUserScope(Sentry, getUser);
}

/** An error boundary fallback that renders the error message and stack trace. */
export const StacktraceFallback: FallbackRender = ({
  componentStack,
  error,
  resetError,
}): JSX.Element => {
  return (
    <div style={{ padding: "8px" }}>
      <h2>
        Error boundary{" "}
        <button type="button" onClick={resetError}>
          Reset
        </button>
      </h2>
      <pre style={{ whiteSpace: "normal" }}>{error.toString()}</pre>
      <pre>{componentStack}</pre>
    </div>
  );
};

/**
 * Wraps Sentry.ErrorBoundary to capture render errors, display a fallback UI
 * and report errors to Sentry. The default configured fallback will render
 * the error and stacktrace when `NODE_ENV === "development"`.
 * In production, the fallback will render nothing.
 *
 * Error boundaries can be nested to capture errors at different parts of a
 * React component tree. At minimum, an error boundary should wrap the top level
 * App component.
 *
 * When using multiple nested error boundaries, use the `beforeCapture` option
 * to customize the scope with additional tags or relevant data to make it easier
 * to identify where the error happened.
 *
 * A crash dialog that allows users to provide additional details is enabled by
 * default.
 */
export const ErrorBoundary = ({
  // TODO(stefan): Also provide a default production fallback UI like the dasher
  // crash page.
  fallback = isDev ? StacktraceFallback : <></>,
  ...restProps
}: ErrorBoundaryProps): JSX.Element => (
  <SentryErrorBoundary {...restProps} fallback={fallback} />
);
