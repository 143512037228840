import _ from "lodash";
import React from "react";
import Tooltip, { TooltipProps } from "@liftoffio/antd/lib/tooltip";
import classNames from "classnames";
import { Icon, IconBaseProps, parseIconProps } from "./Icon";

// TODO(stefan): Move this to a shared lib?
// NOTE(stefan): Base Omit doesn't work here because it squashes union types
// like TooltipProps.
// https://davidgomes.com/pick-omit-over-union-types-in-typescript/
type DistributiveOmit<T, K extends keyof T> = T extends unknown
  ? Omit<T, K>
  : never;

/** Inherits from antd TooltipProps */
type IconTooltipProps = IconBaseProps &
  // The base tooltip defines title as required. This redefines as optional.
  DistributiveOmit<TooltipProps, "title"> & {
    title?: React.ReactNode;
  };

/**
 * Displays an icon that will trigger a tooltip on hover.
 * Any children will be rendered before the icon, and not trigger
 * the tooltip on hover.
 */
export const IconTooltip = ({
  className,
  children,
  title,
  ...props
}: IconTooltipProps): JSX.Element => {
  const hasTooltip = !_.isNil(title) && title !== "";
  const allClassNames = classNames("icon-tooltip", className, {
    "has-tooltip": hasTooltip,
  });
  const type = parseIconProps(props);
  return (
    <div className={allClassNames}>
      {children && <div>{children}</div>}
      {hasTooltip && type && (
        <Tooltip
          title={title}
          overlayClassName={classNames("sb-icon-tooltip", { className })}
          {...props}
        >
          <Icon type={type} />
        </Tooltip>
      )}
    </div>
  );
};

/*  NOTE(deborah)
    The icon for InfoIcon should !always!
    -- follow the content
       => don't tell the user about something they haven't ingested yet
    -- use "INFO" icon
*/

type PresetIconTooltipProps = DistributiveOmit<
  IconTooltipProps,
  "icon" | "type"
>;

export const InfoTooltip = ({
  className,
  ...restProps
}: PresetIconTooltipProps): JSX.Element => (
  <IconTooltip
    {...restProps}
    className={classNames("tooltip-info", className)}
    type="INFO"
  />
);

export const WarnTooltip = ({
  className,
  ...restProps
}: PresetIconTooltipProps): JSX.Element => (
  <IconTooltip
    {...restProps}
    className={classNames("tooltip-warn", className)}
    type="EXCLAIM_CIRCLE"
  />
);

export const ErrorTooltip = ({
  className,
  ...restProps
}: PresetIconTooltipProps): JSX.Element => (
  <IconTooltip
    {...restProps}
    className={classNames("tooltip-error", className)}
    type="EXCLAIM_CIRCLE"
  />
);
