/**
 * Retries the operation with the given timeout and the given number of attempts
 * before failing.
 * Inspired by https://medium.com/@botfather/react-loading-chunk-failed-error-88d0bb75b406
 */
export default function withRetries<T>(
  lazyComponent: () => Promise<T>,
  { attemptsLeft = 3, timeout = 1500 } = {}
): Promise<T> {
  return new Promise<T>(
    (resolve: (r: T) => void, reject: (e: unknown) => void) => {
      lazyComponent()
        .then(resolve)
        .catch((error: unknown) => {
          if (attemptsLeft === 1) {
            reject(error);
            return;
          }
          setTimeout(() => {
            withRetries(lazyComponent, {
              attemptsLeft: attemptsLeft - 1,
              timeout,
            }).then(resolve, reject);
          }, timeout);
        });
    }
  );
}
