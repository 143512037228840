import React from "react";
import {
  ErrorBoundary,
  withErrorBoundary,
} from "@liftoff/components/ErrorBoundary";
import { reportError } from "./utils";

export const formatComponentStack = (componentStack) => {
  try {
    return (
      componentStack
        .split("\n")
        // Filter out noisy minimized component names like 'in N'
        .filter((l) => !/in [a-zA-Z]$/.test(l))
        .join("\n")
    );
  } catch (e) {
    return `Failed to format component stack\n${componentStack}`;
  }
};

export const defaultErrorHandler = (error, { componentStack }) =>
  reportError(`Error Boundary: ${formatComponentStack(componentStack)}`, error);

export const DefaultErrorBoundary = ({ children }) => (
  <ErrorBoundary handleError={defaultErrorHandler}>{children}</ErrorBoundary>
);

/**
 * An error boundary configured to report client errors and hide the component.
 */
// eslint-disable-next-line import/prefer-default-export
export const withDefaultErrorBoundary = withErrorBoundary({
  collapseOnError: true,
  handleError: defaultErrorHandler,
});
