import React from "react";
import { Spin } from "@liftoff/components/Spin";

import "./Initializing.less";

const Initializing = (): JSX.Element => (
  <div className="customer-dashboard initialize-dashboard">
    <img src="/images/state-initializing.svg" alt="Initializing Application" />
    <div className="initialize-spinner">
      <Spin />
      <span className="initialize-text">Initializing</span>
    </div>
  </div>
);

export default Initializing;
